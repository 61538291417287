.pagecontainer {
    background-image: url(/src/images/hero-bg.png);
    min-width: 100%;
    min-height: 100vh;
    padding: 0%;
    /* max-height: 100vh; */
    background-color: #242629;
    animation: bg 30s linear infinite;
}

::-webkit-scrollbar {
    display: none;
}

.pagecontainer1 {
    background-image: url(/src/images/hero-bg.png);
    min-width: 100%;
    min-height: 100vh;
    background-color: #16161A;
    animation: bg 30s linear infinite;
    left: 0%;
    right: 0%;
}

.skills {
    animation: scrollText 29s infinite linear;
    animation-play-state: running;
    animation-duration: 10s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    overflow: hidden;
    white-space: nowrap;
    overflow-x: hidden;

}

@keyframes scrollText {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}



@keyframes bg {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 500px 0;
    }
}